<template>
  <div class="hero is-fullheight">
    <div class="hero-body">
      <div class="container">
        <div class="columns is-multiline is-centered">
          <div class="column is-5-desktop is-8-tablet">
            <div class="card px-6 py-4">
              <div class="card-content">
                <div class="block has-text-centered">
                  <img class="card_logo" src="@/assets/logo-microanalisis-dark.png" alt="Logo Microanalisis">
                </div>
                <template v-if="hasErrorUrlToken">
                  <div class="block">
                    <h1 class="title_component has-text-centered mb-5">
                      Url Invalida
                    </h1>
                    <p class="paragraph has-text-centered">
                      Asegúrese de acceder mediante la url que se le ha enviado a su correo.
                    </p>
                  </div>
                </template>
                <template v-else>
                  <div class="block mb-6">
                    <h1 class="title_component has-text-centered">RECUPERAR CONTRASEÑA</h1>
                  </div>
                  <form @submit.prevent="recoveryPassword" class="columns is-multiline">
                    <div class="column is-12">
                      <label class="label" for="password">
                        Contraseña
                      </label>
                      <div class="field has-addons">
                        <div class="control is-fullwidth">
                          <input
                            id="password"
                            ref="password"
                            class="input icon_btn"
                            :type="inputType"
                            name="password"
                            placeholder="********"
                            v-model="password"
                            v-validate="'required'"
                            data-vv-as="de la contraseña"
                            autocomplete="off"
                          >
                        </div>
                        <div class="control has-icon">
                          <button class="button icon_action" type="button">
                            <i class="material-icons visibility_icon" @click="shPassword()">
                              {{ inputType === 'password' ? 'visibility' : 'visibility_off' }}
                            </i>
                          </button>
                        </div>
                      </div>
                      <span v-show="vverrors.has('password')" class="help is-danger">{{ vverrors.first('password') }}</span>
                    </div>
                    <div class="column is-12">
                      <label class="label" for="password">
                        Confirmar contraseña
                      </label>
                      <div class="field has-addons">
                        <div class="control is-fullwidth">
                          <input
                            id="password_confirmation"
                            class="input icon_btn"
                            :type="inputTypeConfirm"
                            name="password_confirmation"
                            placeholder="********"
                            v-validate="'required|confirmed:password'"
                            data-vv-as="de confirmar contraseña"
                            autocomplete="off"
                          >
                        </div>
                        <div class="control has-icon">
                          <button class="button icon_action" type="button">
                            <i class="material-icons visibility_icon" @click="shPassword('confirm')">
                              {{ inputTypeConfirm === 'password' ? 'visibility' : 'visibility_off' }}
                            </i>
                          </button>
                        </div>
                      </div>
                      <span v-show="vverrors.has('password_confirmation')" class="help is-danger">{{ vverrors.first('password_confirmation') }}</span>
                    </div>
                    <div class="column is-12 mb-6">
                      <div class="block has-text-centered block_btn">
                        <button
                          type="submit"
                          class="button button_micro_dark is-medium is-fullwidth"
                          :class="{ 'is-loading' : loading }"
                        >
                          RECUPERAR CONTRASEÑA
                        </button>
                      </div>
                    </div>
                  </form>
                </template>
                <div class="block">
                  <p class="paragraph has-text-centered mb-3">
                    Si no necesitas cambiar tu contraseña,<br>
                    puedes <router-link class="emphasis_link" to="/login">Iniciar sesión</router-link>
                  </p>

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  name: 'RecoveryPasswordForm',
  data () {
    return {
      loading: false,
      password: null,
      inputType: 'password',
      inputTypeConfirm: 'password',
      urlToken: this.$route.query.token,
      hasErrorUrlToken: true
    }
  },
  methods: {
    ...mapActions(['passwordRecovery']),
    shPassword (confirm) {
      if (confirm) {
        this.inputTypeConfirm = this.inputTypeConfirm === 'password' ? 'text' : 'password'
      } else {
        this.inputType = this.inputType === 'password' ? 'text' : 'password'
      }
    },
    async recoveryPassword () {
      if (await this.$validator.validateAll()) {
        if (this.urlToken) {
          this.loading = true
          const data = {
            token: this.urlToken,
            password: this.password
          }
          const resp = await this.passwordRecovery(data)
          if (resp) {
            this.password = null
            this.$router.push({ name: 'login' })
          }
          this.loading = false
        }
      }
    }
  },
  beforeMount () {
    if (this.urlToken) {
      this.hasErrorUrlToken = false
    }
  }
}
</script>

<style lang="scss" scoped>
  .card {
    border-radius: 5px;
    box-shadow: 0 2px 0 0 rgba(0, 0, 0, 0.05);
    background-color: white;
  }
  .card_logo {
    max-width: 170px;
  }
  .control.is-fullwidth {
    width: 100%;
  }
  .input {
    border: solid 1px #b5b5b5;
  }
  .input.icon_btn {
    border-top: solid 1px #b5b5b5;
    border-right: none;
    border-left: solid 1px #b5b5b5;
    border-bottom: solid 1px #b5b5b5;
  }
  .button.icon_action {
    border-color: transparent;
    border-top: solid 1px #b5b5b5;
    border-right: solid 1px #b5b5b5;
    border-bottom: solid 1px #b5b5b5;
    background-color: white;
  }
  .button.icon_action:focus {
    box-shadow: none;
  }
  .visibility_icon {
    color: #3b3b3b;
  }

  .block_btn {
    padding: 1rem 10%;
  }

  @media screen and (max-width: 768px) {
    .px-6 {
      padding-left: 1rem !important;
      padding-right: 1rem !important;
    }
    .block_btn {
      padding: 1rem 5%;
    }
    .button.is-medium {
      font-size: 1rem;
    }
  }
</style>
